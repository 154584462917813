export function getMealEnding(count)
{
	let cartLength = count % 100;
	let mod = cartLength % 10;

	if (count == 1)
		return 'о'
	else if (cartLength != 11 && mod == 1)
		return 'о';
	else if (mod >= 2 && mod <= 4 && (cartLength < 10 || cartLength > 20))
		return 'а';
	else
		return '';
}

export function getLunchEnding(count)
{
	let cartLength = count % 100;
	let mod = cartLength % 10;

	if (count == 1)
		return ''
	else if (cartLength != 11 && mod == 1)
		return '';
	else if (mod >= 2 && mod <= 4 && (cartLength < 10 || cartLength > 20))
		return 'а';
	else
		return 'ей';
}