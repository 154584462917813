import axios from 'axios'
import qs from 'qs'
import {notification} from "@/utils";
import {errorOutput} from "@/helpers";

export default {
	namespaced: true,
	state: {
		promoCode: "",
		promoLink: "",
		transactionHistory: [],
		transactionHistoryPageCount: 1,
		ordersCurrentPage: 1,
		msg: [],
		cards: [],
		currentCard: null,
	},
	mutations: {
		setPromoCode(state, code)
		{
			state.promoCode = code;
		},
		setPromoLink(state, link)
		{
			state.promoLink = link;
		},
		setOrdersCurrentPage(state, number)
		{
			state.ordersCurrentPage = number;
		},
		setHistory(state, data)
		{
			state.transactionHistory = data.history;
			state.transactionHistoryPageCount = data.pageCount;
			state.msg = data.msg
		},
		setCards(state, data)
		{
			state.cards = data
		},
		setCurrentCard(state, card)
		{
			state.currentCard = card
		}
	},
	getters: {
		getCards(state)
		{
			return state.cards
		},
		getDefaultCard(state)
		{
			return state.cards.find(card => card.DEFAULT_CARD) || null
		},
		getCurrentCard(state)
		{
			return state.currentCard || state.cards.find(card => card.DEFAULT_CARD) || null
		}
	},
	actions: {
		async loadLoyaltyInfo({rootState, commit})
		{
			let data = qs.stringify({'restId': rootState.currentOwnerRestaurant});
			let resp = await axios.post('/personal/getPromoInfo/', data);
			commit('setPromoCode', resp.data.promoCode);
			commit('setPromoLink', resp.data.promoLink);
		},
		async loadHistory({rootState, commit}, payload = {page: 1, sort: 'date', desc: true})
		{
			payload.restId = rootState.currentOwnerRestaurant;
			let resp = await axios.post('/personal/getHistory/', qs.stringify(payload));
			commit('setHistory', resp.data);
		},
		async updateProfileInfo({rootState, commit}, data)
		{
			const params = qs.stringify(data);
			return await axios.post('/personal/updateProfileInfo/', params);
		},
		async fetchCards({commit})
		{
			try
			{
				const {data} = await axios.get('/personal/getCards');

				if (!data.success) return errorOutput(data.errors);

				commit('setCards', data.cards);
			} catch (e)
			{
				notification({title: 'Произошла ошибка при получении карт', type: 'error'});
			}
		},
		async fetchDeleteCard({state, commit, dispatch}, id)
		{
			try
			{
				const {data} = await axios.post('/personal/deleteCard', qs.stringify({id}));

				if (!data.success) return errorOutput(data.errors);

				const updatedCards = state.cards.filter(card => card.ID !== id);

				// Если удалили активную карту, то делаем активной первую в списке, если список не пустой
				if (updatedCards.length)
				{
					const cords = updatedCards.some(card => card.DEFAULT_CARD);
					if (!cords) await dispatch('fetchSaveCard', updatedCards[0].ID);
				}

				commit('setCards', updatedCards);

				notification({title: 'Карта успешно удалена'});
			} catch (e)
			{
				notification({title: 'Произошла ошибка при удалении карты', type: 'error'});
			}
		},
		async fetchSaveCard({state, commit}, id)
		{
			try
			{
				const {data} = await axios.post('/personal/setDefaultCard', qs.stringify({id}));

				if (!data.success) return errorOutput(data.errors);

				if (state.cards.length)
				{
					if (!id) return state.cards.map(card => card.DEFAULT_CARD = false);

					state.cards.map(card => card.ID === id ? card.DEFAULT_CARD = true : card.DEFAULT_CARD = false);

					commit('setCurrentCard', state.cards.find(card => card.DEFAULT_CARD));
				}

				notification({title: 'Карта по умолчанию успешно установлена'});
			} catch (e)
			{
				notification({title: 'Произошла ошибка при сохранении карты', type: 'error'});
			}
		},
	}
}