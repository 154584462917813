import axios from 'axios'
import qs from 'qs'
import {notification} from "@/utils";

export default {
	namespaced: true,
	state: {
		holdPrice: 0,
		restPointsAmount: 0,
		restaurantDetail: null,
		restDeliveryTime: null,
		restDeliveryTypes: []
	},
	mutations: {
		setHoldPrice(state, payload)
		{
			state.holdPrice = payload;
		},
		setRestPointsAmount(state, payload)
		{
			state.restPointsAmount = payload;
		},
		setRestaurantDetail(state, payload)
		{
			state.restaurantDetail = payload;
		},
		setRestDeliveryTime(state, payload)
		{
			state.restDeliveryTime = payload;
		},
		setRestDeliveryTypes(state, payload)
		{
			state.restDeliveryTypes = payload;
		}
	},
	getters: {
		getRestDeliveryTime(state)
		{
			return state.restDeliveryTime;
		},
		getRestDeliveryTypes(state)
		{
			return state.restDeliveryTypes;
		},
	},
	actions: {
		async fetchRestDeliveryTypes({rootState, commit})
		{
			const errorText = 'Произошла ошибка при получении типов доставки'

			if (!rootState.currentRestarauntId) return;

			try
			{
				const params = qs.stringify({restId: rootState.currentRestarauntId})
				const {data} = await axios.post('/restaurant/getDeliveryTypes', params);

				if (!data.success) return notification({
					title: data.msg || errorText,
					type: 'error'
				});

				commit('setRestDeliveryTypes', data.deliveryTypes);

				rootState.order.currentDeliveryType = data.deliveryTypes[0].value;
			} catch (e)
			{
				notification({title: errorText, type: 'error'});
			}
		},
		async loadRestPoints({rootState, commit})
		{
			let data = qs.stringify({
				'currentOwnerRestaurant': rootState.currentOwnerRestaurant,
			});
			let resp = await axios.post('/restaurant/getRestaurantPoints/', data);
			commit('setHoldPrice', resp.data.frozenPoints);
			commit('setRestPointsAmount', resp.data.restPoints);
		},
		async doPointsOut({rootState, commit, dispatch}, sum)
		{
			let data = qs.stringify({
				'sum': sum,
				'currentOwnerRestaurant': rootState.currentOwnerRestaurant,
			});
			let resp = await axios.post('/restaurant/pointsOut/', data);
			if (resp.data.success)
			{
				dispatch('loadRestPoints');
			}
		},
		async loadRestaurantDetail({rootState, commit})
		{
			let data = qs.stringify({
				'currentOwnerRestaurant': rootState.currentOwnerRestaurant,
			});
			let resp = await axios.post('/restaurant/getRestaurantDetail/', data);
			commit('setRestaurantDetail', resp.data.restaurantDetail);
		},
		async getRestDeliveryTime({rootState, commit}, id)
		{
			let data = qs.stringify({
				'restId': rootState.currentRestarauntId || id,
			});

			let resp = await axios.post('/restaurant/getDeliveryTimes', data);
			commit('setRestDeliveryTime', resp.data.delivery_time_range);
		}
	}
}
