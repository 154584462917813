import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition)
	{
		if (to.name.startsWith('personal')) return
		document.querySelector('html').scrollTop = 0
	},
	routes: [
		{
			path: '/lk',
			component: () => import('./pages/RestLK.vue'),
			children: [
				{
					path: '',
					name: 'lk_orders',
					component: () => import('@/components/layout/Rest/Orders.vue'),
				},
				{
					path: 'menu',
					name: 'lk_menu',
					component: () => import('@/components/layout/Rest/Menu.vue'),
				},
				{
					path: 'restaurant',
					name: 'lk_restaurant',
					component: () => import('@/components/layout/Rest/Restaurant')
				},
				{
					path: 'bills',
					name: 'lk_bills',
					component: () => import('@/components/layout/Rest/Bills')
				},
				{
					path: 'personal',
					name: 'lk_personal',
					component: () => import('@/components/layout/Rest/Personal')
				},
				{
					path: 'mydishes',
					name: 'lk_mydishes',
					component: () => import('@/components/layout/Rest/MyDishes')
				}
			]
		},
		{
			path: '/',
			component: () => import('./scaffolds/Main.vue'),
			meta: {name: 'Вкусно и Быстро'},
			children: [
				{
					path: '/404',
					name: 'not_found',
					component: () => import('./pages/NotFoundPage.vue')
				},
				{
					path: '',
					name: 'Index',
					component: () => import( /* webpackChunkName: "IndexPage" */ './pages/IndexPage.vue'),
				},
				{
					path: '/auth',
					name: 'AuthPage',
					component: () => import('./pages/AuthPage.vue')
				},
				{
					path: '/order_confirmed/:id',
					name: 'OrderConfirmedPage',
					component: () => import('./pages/OrderConfirmedPage.vue')
				},
				{
					path: '/coverage',
					name: 'Coverage',
					component: () => import( /* webpackChunkName: "CoveragePage" */ './pages/CoveragePage.vue'),
					meta: {name: 'География покрытия'}
				},
				{
					path: '/menu',
					name: 'Menu',
					component: () => import( /* webpackChunkName: "MenuPage" */ './pages/MenuPage.vue'),
					meta: {name: 'Меню'}
				},
				{
					path: '/chat',
					name: 'Chat',
					component: () => import( /* webpackChunkName: "MenuPage" */ './pages/ChatPage.vue'),
					meta: {name: 'Чат'}
				},
				{
					path: '/rest/:id',
					name: 'RestaurantPage',
					component: () => import( /* webpackChunkName: "MenuPage" */ './pages/MenuPage.vue'),
					meta: {name: 'Ресторан'}
				},
				{
					path: '/constructor',
					name: 'ConstructorPage',
					component: () => import('./pages/ConstructorPage.vue'),
					meta: {name: 'Конструктор блюд'}
				},
				{
					path: '/finished_build',
					name: 'FinishedBuildPage',
					component: () => import('./pages/FinishedBuildPage.vue'),
					meta: {name: 'Завершение сборки'}
				},
				{
					path: '/cart',
					name: 'CartPage',
					component: () => import('./pages/CartPage.vue'),
					meta: {name: 'Корзина'}
				},
				{
					path: '/order',
					name: 'OrderPage',
					component: () => import('./pages/OrderPage.vue'),
					meta: {name: 'Оформление заказа'}
				},
				{
					path: '/breakfast',
					name: 'BreakfastPage',
					component: () => import('./pages/MealPage.vue'),
					meta: {name: 'Завтраки'}
				},
				{
					path: '/dinner',
					name: 'DinnerPage',
					component: () => import('./pages/MealPage.vue'),
					meta: {name: 'Ужины'}
				},
				{
					path: '/payment',
					name: 'PaymentPage',
					component: () => import('./pages/RombPage.vue'),
					meta: {name: 'Оплата'}
				},
				{
					path: '/typography',
					name: 'Typography',
					component: () => import('./pages/Typography.vue')
				},
				{
					path: '/personal',
					name: 'PersonalPage',
					component: () => import('./pages/Personal/Wrapper.vue'),
					children: [
						{
							name: 'personal_orders',
							path: '',
							alias: 'orders',
							component: () => import('./pages/Personal/Orders.vue'),
							meta: {name: 'Мои заказы'}
						},
						{
							name: 'personal_loyalty_friends',
							path: 'loyalty/friends',
							component: () => import('./pages/Personal/FriendsList.vue'),
							meta: {name: 'Программа лояльности / Список друзей'},
						},
						{
							name: 'personal_loyalty',
							path: 'loyalty',
							component: () => import('./pages/Personal/Loyalty.vue'),
							meta: {name: 'Программа лояльности'},
						},
						{
							name: 'personal_info',
							path: 'info',
							component: () => import('./pages/Personal/Info.vue'),
							meta: {name: 'Моя информация'}
						},
						{
							name: 'personal_pay',
							path: 'pay',
							component: () => import('./pages/Personal/Pay.vue'),
							meta: {name: 'Мои способы оплаты'}
						},
						{
							name: 'personal_address',
							path: 'address',
							component: () => import('./pages/Personal/Address.vue'),
							meta: {name: 'Адресная книга'}
						},
					]
				},
				{
					path: '/report',
					name: 'ReportMap',
					component: () => import('./pages/ReportMap.vue')
				},
			]
		},
		{
			path: '*',
			redirect: '/404'
		},
	]
})
