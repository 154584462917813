/**
 * Проверяет, использует ли пользователь мобильное устройство или ширина окна меньше или равна 767.
 *
 * @param {string} checkType - тип проверки
 * @return {boolean} указывает, использует ли пользователь мобильное устройство или ширина окна меньше или равна 767
 */
export const mobileCheck = (checkType) =>
{
	if (checkType === 'mobileApp') return !!(window.navigator.userAgent.match(/mobileApp/i));

	if (checkType === 'width') return window.innerWidth <= 767;

	return !!(window.navigator.userAgent.match(/mobileApp/i)) || window.innerWidth <= 767;
}