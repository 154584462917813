import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VeeValidate, {ValidationProvider, ValidationObserver} from "vee-validate";
import config from "@/VeeValidateConfig.js";
import VueCookie from "vue-cookie";
import store from "./store/index.js";
import Popup from "./plugins/popup/index.js";
import {VueMaskDirective} from "v-mask";
import VueTouch from "vue-touch";
import YandexShare from '@cookieseater/vue-yandex-share';
import VuePortal from "@linusborg/vue-simple-portal";
import SmoothScrollbar from 'vue-smooth-scrollbar';

import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import VCalendar from 'v-calendar';
// import VueCalendar from 'v-calendar/lib/components/calendar.umd';
// import VueDatepicker from 'v-calendar/lib/components/date-picker.umd';
import {EmojiPickerPlugin} from 'vue-emoji-picker'
import sockets from './plugins/socket/index.js';
import Maska from "maska";
import Notifications from 'vue-notification'

Vue.config.productionTip = false;
Vue.use(Notifications)
Vue.use(SmoothScrollbar);
Vue.directive("mask", VueMaskDirective);
Vue.use(VueTouch, {name: "v-touch"});
Vue.use(Popup);
Vue.use(Maska);
Vue.use(sockets);
Vue.use(EmojiPicker);
Vue.use(VueCookie);
Vue.use(YandexShare);
Vue.use(VuePortal, {
	selector: "#portal-target",
});
Vue.use(EmojiPickerPlugin)
// Vue.component('VueDatepicker', VueDatepicker);
// Vue.component('VueCalendar', VueCalendar);

Vue.use(VCalendar, {
	componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});
axios.defaults.withCredentials = true;
axios.defaults.headers.common = {};
axios.defaults.headers.common.accept = "application/json";

Vue.prototype.$axios = axios.create({
	baseURL: process.env.VUE_APP_API_ENDPOINT,
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VeeValidate, config);

router.beforeEach(async function (to, from, next)
{
	if (typeof to.query.promo != "undefined" && !router.app.$cookie.get("promo"))
		router.app.$cookie.set("promo", to.query.promo, 1);

	store.dispatch("remindUser").then(next);

	if (to.hash) store.commit("setActiveHash", to.hash);

	next();
});

Sentry.init({
	Vue,
	dsn: "https://c7b56029b04fca05dc661cdbf30b7704@o4506257192517632.ingest.sentry.io/4506257198219264",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [/^https:\/\/www\.vkusnoibistro\.com\/api/],
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 ** Закрытие формы по клику на боди
 **/
Vue.directive("click-outside", {
	/**
	 *
	 */
	bind: (el, binding, vnode) =>
	{
		el.fisrtClick = true;
		el.clickOutsideEvent = (event) =>
		{
			if (el.fisrtClick === true)
			{
				return (el.fisrtClick = false);
			}
			if (!(el === event.target || el.contains(event.target)))
			{
				vnode.context[binding.expression](event, binding.arg);
				event.stopPropagation();
			}
		};
		document.body.addEventListener("click", el.clickOutsideEvent);
	},
	/**
	 **
	 **/
	unbind: function (el)
	{
		document.body.removeEventListener("click", el.clickOutsideEvent);
		el.clickOutsideEvent = null;
	},
});

Vue.directive("scroll", {
	inserted: function (el, binding)
	{
		let f = function (evt)
		{
			if (binding.value(evt, el))
			{
				window.removeEventListener("scroll", f);
			}
		};
		window.addEventListener("scroll", f, {
			passive: "true",
		});
	},
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
