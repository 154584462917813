import axios from 'axios'
import qs from 'qs'
import {DateTime} from "luxon";
import Vue from 'vue'

import debounce from "lodash/debounce";

const debounceState = {};

async function debounceUpdateActivity(payload)
{
	let key = payload.productId + ' ' + payload.date;
	if (!debounceState[key])
	{
		debounceState[key] = {
			debounce: debounce(
				() =>
				{
					api('/lkmenu/updateActivity/', debounceState[key].payload);
					delete debounceState[key];
				},
				1000
			)
		}
	}
	debounceState[key].payload = {
		...debounceState[key].payload,
		...payload
	}
	debounceState[key].debounce()
}


async function api(method, payload = {})
{
	try
	{
		let resp = await axios.post(method, qs.stringify(payload));
		return resp.data
	} catch (error)
	{
		console.error(error)
		return {success: false, error}
	}
}

export default {
	namespaced: true,
	state: {
		viewState: null,
		currentPeriodType: 'lunch',
		currentDate: DateTime.local(),

		allMeals: {},
		allMealTypes: [],
		mealActivity: {},

		currentMealGroup: null,
		scrollMealGroup: null,

		detailProductId: null,

		mealSelectedDays: {},
		mealsTypesForLunches: [],

		popup: {
			x: 0,
			y: 0,
			show: false,
			productId: null
		},

		isReady: false,
		error: null,
		accessErrorMsg: 'Вы имеете доступ в ЛК кухни, но не являетесь работником ни одного ресторана',
		loading: true,


		showCalendar: false,
		showProductCalendar: false,

		batchActivity: {},

		intersectionProcess: false,

		deliveryTimes: null,
	},

	getters: {
		getDeliveryTimes(state)
		{
			return state.deliveryTimes;
		},
		getPeriodType(state)
		{
			return state.currentPeriodType;
		},
		sortDict(state)
		{
			return Object.fromEntries(state.allMealTypes.map((el, i) => [el, i]))
		},
		mealList(state, getters)
		{

			return Object.values(state.allMeals)
				.map(el => ({
					...state.mealActivity[el.id],
					...el,
					enabled: (el.id in state.mealActivity),
					type: (el.type === 'Вторые') ? 'Горячие' : el.type
				}))
				.sort((a, b) =>
				{
					let d = getters.sortDict[a.type] - getters.sortDict[b.type]

					return d === 0 ? a.name.localeCompare(b.name) : d
				})
		},
		activeMealList(state, getters)
		{
			return getters.mealList.filter(el => el.enabled)
		},
		activeMealCount(state, getters)
		{
			let res = {};
			for (let meal of getters.activeMealList)
			{
				if (!(meal.type in res)) res[meal.type] = {all: 0, lunch: 0}
				res[meal.type].all += 1
				if (meal.inLunch) res[meal.type].lunch += meal.inLunch
			}
			return res
		},
		mealTypes(state, getters)
		{
			let res = {};
			for (let meal of getters.mealList)
			{
				res[meal.type] = 0
			}
			return state.allMealTypes.filter(el => el in res)
		},

		availableMealList(state, getters)
		{
			return getters.mealList.filter(el => !el.enabled)
		},
		availableMealTypes(state, getters)
		{
			let res = {};
			for (let meal of getters.availableMealList)
			{
				res[meal.type] = 0
			}
			return state.allMealTypes.filter(el => el in res)
		},

		detailMeal(state)
		{
			return {
				...state.mealActivity[state.detailProductId],
				...state.allMeals[state.detailProductId],
				enabled: (state.detailProductId in state.mealActivity)
			}

		},
		popupMeal(state)
		{
			return {
				...state.mealActivity[state.popup.productId],
				...state.allMeals[state.popup.productId],
				enabled: (state.popup.productId in state.mealActivity)
			}
		},
		lunchProblem(state, getters)
		{
			let e = Object.fromEntries(state.mealsTypesForLunches.map(el => [el, false]))
			e.any = false
			for (let meal of getters.activeMealList)
			{
				if (meal.inLunch && (meal.type in e))
				{
					e[meal.type] = true
					e.any = true
				}
			}
			if (e.any)
			{
				e.any = !Object.values(e).every(e => e);
			}
			return e
		}
	},
	mutations: {
		setDeliveryTimes(state, data)
		{
			state.deliveryTimes = data;
		},
		setCurrentPeriodType(state, type)
		{
			state.currentPeriodType = type
		},
		setBatchActivity(state, batchActivity)
		{
			state.batchActivity = batchActivity
		},
		setCalendar(state, flag)
		{
			state.showCalendar = flag
		},
		setProductCalendar(state, flag)
		{
			state.showProductCalendar = flag
		},

		init(state, data)
		{
			state.allMeals = data.allMeals
			state.allMealTypes = data.allMealTypes
			state.mealsTypesForLunches = data.mealsTypesForLunches
			state.isReady = true

			if (this.getters['lkmenu/mealList'].length)
				this.commit('lkmenu/setCurrentMealGroup', this.getters['lkmenu/mealList'][0].type);
		},
		setMealActivity(state, data)
		{
			state.mealActivity = data
		},
		error(state, msg)
		{
			state.error = msg
		},
		setCurrentDate(state, data)
		{
			state.currentDate = data
		},
		setSwitchMealGroupProcess(state, data)
		{
			state.intersectionProcess = data
		},
		setDetailProductId(state, productId)
		{
			state.detailProductId = productId === state.detailProductId ? null : productId
		},
		setCurrentMealGroup(state, group)
		{
			state.currentMealGroup = group
		},
		setScrollMealGroup(state, data)
		{
			state.scrollMealGroup = data
		},
		toggleMealSelectedDays(state, day)
		{
			if (day in state.mealSelectedDays)
			{
				Vue.delete(state.mealSelectedDays, day)
			} else
			{
				Vue.set(state.mealSelectedDays, day, 1)
			}
		},
		clearMealSelectedDays(state)
		{
			state.mealSelectedDays = {}
		},
		setLoading(state, data)
		{
			state.loading = data
		},
		showPopup(state, {event, productId})
		{
			state.popup.show = true


			if (window.innerWidth - event.clientX - 226 < 0)
			{
				state.popup.x = window.innerWidth - 226
			} else
			{
				state.popup.x = event.clientX - 10
			}

			if (window.innerHeight - event.clientY - 170 < 0)
			{
				state.popup.y = window.innerHeight - 170
			} else
			{
				state.popup.y = event.clientY - 10
			}

			state.popup.productId = productId
		},
		closePopup(state)
		{
			state.popup.show = false
		},
		updateActivity(state, payload)
		{
			if (!payload.productId) payload.productId = state.detailProductId;

			if (payload.enabled)
			{
				Vue.set(state.mealActivity, payload.productId, {
					...state.mealActivity[payload.productId],
					...payload
				})
			} else
			{
				Vue.delete(state.mealActivity, payload.productId)
			}
		}
	},
	actions: {
		async init({commit, dispatch, state, rootState}, periodType)
		{
			let data = await api('/lkmenu/getAllMealsNTypes/', {
				currentOwnerRestaurant: rootState.currentOwnerRestaurant,
				timeRange: periodType
			});
			if (!data.success)
			{
				commit('setLoading', false)
				if (rootState.user.LK_ACCESS && rootState.user.NO_REST)
					return commit('error', state.accessErrorMsg);
				return commit('error', data.error);
			}
			commit('init', data)
			await dispatch('loadActivity')
		},
		async loadActivity({commit, state, rootState})
		{
			commit('setLoading', true)
			let data = await api(
				'/lkmenu/',
				{
					date: state.currentDate.toISODate(),
					currentOwnerRestaurant: rootState.currentOwnerRestaurant,
				}
			);
			if (!data.success)
			{
				commit('setLoading', false)
				if (rootState.user.LK_ACCESS && rootState.user.NO_REST)
					return commit('error', state.accessErrorMsg);
				return commit('error', data.error);
			}

			if (Array.isArray(data.activeMealInfo))
				data.activeMealInfo = {};

			commit('setMealActivity', data.activeMealInfo)
			commit('setDeliveryTimes', data.deliveryTimes)
			commit('setLoading', false)
		},
		async updateActivity({dispatch, state, commit, rootState}, payload)
		{
			if (payload.inLunch || payload.action) payload.enabled = true
			payload = {
				date: state.currentDate.toISODate(),
				productId: state.detailProductId,
				...payload
			};
			if (payload.enabled === false)
			{
				payload.inLunch = false
				payload.action = false

			}

			commit('updateActivity', payload);

			payload.currentOwnerRestaurant = rootState.currentOwnerRestaurant;
			debounceUpdateActivity(payload);


			// let data = await api('/lkmenu/updateActivity/', payload);
			// if (!data.success) return commit('error',data.error);
		},
		async updateBatchActivity({dispatch, state, commit, rootState}, payload)
		{
			commit('setLoading', true)
			payload.productId = state.detailProductId;
			payload.date = Object.keys(state.mealSelectedDays);
			payload.currentOwnerRestaurant = rootState.currentOwnerRestaurant;
			let data = await api('/lkmenu/updateBatchActivity/', payload);
			if (!data.success) return commit('error', data.error);
			await dispatch('loadActivity')
		}
	}


}
