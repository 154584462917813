import IO from "socket.io-client";
import axios from 'axios'

export default {
	io: false,
	install: async function (Vue, options)
	{
		Vue.prototype.$socket = this;
	},

	async init()
	{
		let response = await axios.get('/auth/getToken');

		if (!response?.data?.token)
			return;

		this.io = new IO(
			{
				path: "/ws/",
				transports: ["websocket"],
				query: {token: response.data.token},
			}
		);
	}
};


