export default {
	methods:
		{
			/**
			 * Как только подключен JivoSite, скрываем его на странице
			 */
			initJivo()
			{
				const observer = new MutationObserver(mutations =>
				{
					mutations.forEach(mutation =>
					{
						mutation.addedNodes.forEach(node =>
						{
							if (node.tagName === 'JDIV') node.style.display = 'none';
						});
					});
				});

				observer.observe(document.body, {childList: true, subtree: false});
			},
			/**
			 * Если выбран нужный ресторан и экран больше 1000px, то показываем Jivo, в противном случае скрываем
			 */
			showJivo(restId)
			{
				const jdiv = document.querySelector('jdiv');

				if (!jdiv || !restId || window.innerWidth < 1000) return;

				if (restId === "7242" || restId === "16338")
				{
					jdiv.style.display = 'block'
				} else
				{
					jdiv.style.display = 'none'
				}
			},
			/**
			 * Скрываем Jivo
			 */
			hideJivo()
			{
				const jdiv = document.querySelector('jdiv');

				if (!jdiv) return;

				jdiv.style.display = 'none'
			}
		}
}