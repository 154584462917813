'use strict'

export default {
	namespaced: true,

	state: {
		device: {
			width: null,
			type: null,
			orientation: null,
			realVh: null
		},

		headerVisibility: true
	},

	getters: {},

	mutations: {
		SET_DEVICE_PARAMS(state, {width, type, orientation, realVh})
		{

			state.device.width = width;
			state.device.type = type;
			state.device.orientation = orientation;
			state.device.realVh = realVh;

		},

		SET_HEADER_VISIBILITY(state, value)
		{
			state.headerVisibility = value;
		}
	},

	actions: {

		/**
		 * Обновление параметров устройства при изменении экрана или его ориентации
		 */
		updateDeviceParams({state, commit}, {width, height})
		{

			let type = 'desktop';
			let orientation = width > height ? 'landscape' : 'portrait'

			if (width <= 1000 && width > 567 && orientation == 'portrait')
			{
				type = 'tablet';
			} else if (width <= 567 && orientation == 'portrait' || height <= 567 && orientation == 'landscape')
			{
				type = 'mobile';
			}

			commit('SET_DEVICE_PARAMS', {
				width, type, orientation,
				realVh: height / 100
			});

		}

	}
}
