/**
 * Набор функций для обработки промокодов
 */
export default {

	/**
	 * Обрабатывает купон и возвращает результат его обработки
	 * @param {Object} cart - Корзина с ланчами и продуктами
	 * @param {Object} promoCoupon - Данные купона
	 * @param {Object} saleDiscount - Действия и условия купона
	 * @returns {Object} Итоговый результат обработки
	 */
	handleCoupon(cart, order, promoCoupon = null, saleDiscount = null)
	{

		if (!promoCoupon && !saleDiscount) return {discountSum: 0, gifts: []};

		if (!this.checkPromoCoupon(promoCoupon) || !this.checkConditions(saleDiscount, cart, order))
			return {discountSum: 0, gifts: []};

		return this.applyActions(saleDiscount, cart, order);

	},

	/**
	 * Проверка основных правил промокупона
	 * @param {Object} promoCoupon - Основные данные промокупона
	 * @returns {Boolean} Результат проверки
	 */
	checkPromoCoupon(promoCoupon)
	{

		if (promoCoupon.ACTIVE !== 'Y') return false;

		let dateStatus = false;
		let useCountStatus = false;


		let activeFrom = new Date(Date.parse(promoCoupon.ACTIVE_FROM.replace(' ', 'T'))).getTime();
		let activeTo = new Date(Date.parse(promoCoupon.ACTIVE_TO.replace(' ', 'T'))).getTime();

		// Проверка даты
		// Если дата начала совпадает с датой окончания, купон считается бессрочным
		if (activeFrom === activeTo)
		{
			dateStatus = true;
		}

		// Иначе текущая дата сравнивается с датами купона
		else if (activeFrom !== activeTo)
		{

			let curDate = new Date().getTime();

			dateStatus = curDate >= activeFrom && curDate <= activeTo ?
				true : false;

		}


		// Проверка количества использований
		// Если купон многоразовый, количество использований не должно быть выше максимального
		if (promoCoupon.TYPE == 4 && promoCoupon.USE_COUNT < promoCoupon.MAX_USE)
			useCountStatus = true;

		// Если купон одноразовый, количество использований должно быть 0
		else if (promoCoupon.TYPE == 2 && promoCoupon.USE_COUNT == 0)
			useCountStatus = true;

		else
			useCountStatus = false;

		// Возврат итогового результата
		return dateStatus && useCountStatus;

	},


	/**
	 * Проверка условий в промокупоне
	 * @param {Object} saleDiscount - Условия и действия промокупона
	 * @param {Object} cart - Корзина пользователя
	 * @param {Object} order - Общие данные о заказе
	 * @returns Результат проверки всех условий
	 */
	checkConditions(saleDiscount, cart, order)
	{

		// Результаты проверки каждого условия
		let condChecks = [];

		// Проверка каждого условия
		for (let i = 0; i < saleDiscount.CONDITIONS.CHILDREN.length; i++)
		{

			let cond = saleDiscount.CONDITIONS.CHILDREN[i];
			let val = cond.DATA.value ?? cond.DATA.Value;

			// Определение типа логики условия
			switch (cond.DATA.logic)
			{

				case 'Great':
					condChecks[i] = order.fullSumm > val;
					break;

				case 'Less':
					condChecks[i] = order.fullSumm < val;
					break;

				case 'EqGr':
					condChecks[i] = order.fullSumm >= val;
					break;

				case 'EqLs':
					condChecks[i] = order.fullSumm <= val;
					break;

				case 'Equal':
					condChecks[i] = order.fullSumm === val;
					break;

				case 'Not':
					condChecks[i] = order.fullSumm !== val;
					break;

				default:
					throw ('Неизвестное условие в менеджере купонов');
					break;
			}

		}


		// Определение общего результата условий
		let condResult = condChecks[0];

		for (let i = 1; i < condChecks.length; i++)
		{

			if (saleDiscount.CONDITIONS.DATA.All === 'And')
				condResult = condResult && condChecks[i];
			else
				condResult = condResult || condChecks[i];
		}

		// Инвертирование общего результата, если это неообходимо
		if (saleDiscount.CONDITIONS.DATA.True === 'False')
			condResult = !condResult;

		return condResult;

	},

	/**
	 * Применяет действия из купона к корзине и заказу
	 * @param {Object} saleDiscount - Условия и действия промокупона
	 * @param {Array} cart - Корзина пользователя
	 * @param {Object} order - Данные заказа
	 * @returns Сумму скидки и дополнительные опции
	 */
	applyActions(saleDiscount, cart, order)
	{
		let discountSum = 0;

		// Применение всех действий
		for (let i = 0; i < saleDiscount.ACTIONS.CHILDREN.length; i++)
		{
			let action = saleDiscount.ACTIONS.CHILDREN[i];

			// Проверка типа действия
			switch (action.DATA.Type)
			{
				case 'Discount':
					discountSum += this.calcDiscount(action, cart);
					break;
			}

		}

		return {discountSum, gifts: []};

	},


	/**
	 * Рассчитывает скидку для корзины для Discount-действия
	 * @param {Object} action - Действие с типом "Discount"
	 * @param {Array} cart - Корзина с товарами
	 * @returns Итоговую скидку для текущего действия
	 */
	calcDiscount(action, cart)
	{

		let discountSum = 0;

		// Применение скидки к ланчам
		if (cart.lunches)
		{
			cart.lunches.forEach(item =>
			{

				if (action.DATA.Unit === 'Perc')
				{
					discountSum += item.lanch.price * item.count / 100 * action.DATA.Value;
				}

			});
		}

		// Применение скидки к продуктам
		if (cart.meals)
		{
			cart.meals.forEach(item =>
			{

				if (action.DATA.Unit === 'Perc')
				{
					discountSum += item.product.price * item.count / 100 * action.DATA.Value;
				} else
				{
					throw 'Неизвестный тип скидочных единиц купона';
				}

			});
		}

		// Проверка выхода скидки за допустимый предел
		if (action.DATA.Max !== 0 && action.DATA.Max < discountSum)
			discountSum = action.DATA.Max;

		return Math.round((discountSum + Number.EPSILON) * 100) / 100;

	}

}