import {Validator} from "vee-validate";

Validator.extend('phone', {
	validate: value => value.length === 18 && value[0] === '+'
});

/**
 ** locales for validate
 **/
const dict =
	{
		ru:
			{
				messages:
					{
						/**
						 * сообщение при ошибке
						 */
						required: function ()
						{
							return "Поле не заполнено"
						},
						/**
						 * сообщение при ошибке
						 */
						min: function (fieldName)
						{
							return `Неверный формат, поле ${fieldName} должно быть длиннее`;
						},
						phone: "Введен неверный номер телефона",
						/**
						 * сообщение при ошибке
						 */
						max: function (fieldName)
						{
							return `Неверный формат, поле ${fieldName} должно быть короче`
						},
						/**
						 * сообщение при ошибке
						 */
						email: function ()
						{
							return "Неверный формат электронной почты. Пример почты: name@domen.ru"
						},
						/**
						 * сообщение при ошибке
						 */
						numeric: function ()
						{
							return "Неверный формат, должны быть только цифры"
						},
						/**
						 * сообщение при ошибке
						 */
						length: function ()
						{
							return "Неверный формат"
						},
						/**
						 * сообщение при ошибке
						 */
						regex: function (fieldName)
						{
							return `Неверный формат поля "${fieldName}"`;
						},
						/**
						 * сообщение при ошибке
						 */
						confirmed: function (fieldName)
						{
							return `Поле ${fieldName} и Пароль должны совпадать`;
						}
					}
			}
	};

export default {
	aria: false,
	classNames: {},
	classes: false,
	delay: 0,
	dictionary: dict,
	errorBagName: 'errors', // change if property conflicts
	events: '',
	fieldsBagName: 'fields',
	i18n: null, // the vue-i18n plugin instance
	i18nRootKey: 'validations', // the nested key under which the validation messages will be located
	inject: true,
	locale: 'ru',
	validity: false
};