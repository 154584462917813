import {mapActions, mapGetters, mapMutations} from "vuex";
import {redirectBasedOnTimeRange} from "@/helpers";
import {notification} from "@/utils";

export default {
	computed:
		{
			...mapGetters({
				currentBasket: 'getCurrentBasket',
				user: 'getUser',
			})
		},
	methods: {
		...mapMutations({
			setPayer: 'setPayer',
			addMealToCart: 'addMealToCart',
			addLunchToCart: 'addLunchToCart',
			removeMealFromCart: 'removeMealFromCart',
			removeLunchFromCart: 'removeLunchFromCart',
			setCartDetails: 'setCartDetails',
			setCurrentBasket: 'setCurrentBasket',
			addInviteUser: 'addInviteUser',
			deleteUser: 'deleteUser',
			setUserDone: 'setUserDone',
		}),
		...mapActions({
			getCart: 'getCart',
			resetCartToDefault: 'resetCartToDefault',
		}),
		// Инициализация событий сокета
		async handleSocketEvents()
		{
			if (!this.$socket.io) return;

			// Запуск событий сокета
			this.handleCartStatusSocketEvents();
			this.handleCartUserModificationSocketEvents();
			this.handleCartModificationSocketEvents();
		},
		// Обработка событий, связанных с состоянием коллективной корзины (продление, запуск, очистка, удаление)
		handleCartStatusSocketEvents()
		{
			this.$socket.io.on("collective-cart-status", async ({action, data}) =>
			{
				const {
					cart_type_id,
					payer,
					time_range,
					cart_type_name,
					expiration_time,
					user_id,
					user_name,
					owner_id
				} = data;

				if (!cart_type_id) return;

				const isCartTypeIdActive = this.currentBasket?.id === cart_type_id;

				// Обработка сценария запуска корзины с всплывающим окном (в случае, если мы не являемся инициатором)
				if (action === "run" && owner_id !== this.user.ID)
				{
					/**
					 * Если мы находимся на странице с едой и таймер запускается на текущей корзине,
					 * то кидаем пользователя на нужную страницу.
					 *
					 * Это нужно для того, чтобы если пользователь закрыл попап, не переходя на страницу с запущенной корзиной,
					 * но он уже на странице с едой текущей корзины и она была запущена на другой день или другое время, то его
					 * перекидывало на ту страницу, где она была запущена. В противном случае ему разблокируется доступ к добавлению блюд,
					 * но пользователь будет добавлять их в никуда
					 */
					if (isCartTypeIdActive)
					{
						const path = this.$route.path.split('/')[1];

						/** Обновляем таймер у currentBasket, для того,
						 * чтобы кнопка корзины с "Настройки корзины" поменялась на "Настройка времени заказа" */
						this.setCurrentBasket({
							...this.currentBasket,
							timer: expiration_time
						})

						this.setPayer(payer);

						if (['menu', 'dinner', 'breakfast', 'rest'].includes(path)) redirectBasedOnTimeRange(time_range);
					}

					return;
				}

				// Обработка сценария завершения сборки корзины
				if (action === "cart_done" && isCartTypeIdActive)
				{
					this.setUserDone(user_id);

					const title = `Пользователь ${user_name || ''} закончил сборку корзины ${cart_type_name || ''}.`
					notification({title})
				}

				// Обработка сценария удаления корзины
				if (action === "delete")
				{
					/**  Если удалена текущая корзина, то удаляем ее и делаем главной Основную,
					 * в противном случае просто удаляем коризну из списка наших корзин */
					if (isCartTypeIdActive)
					{
						await this.deleteAndSetMainCart();

						const path = this.$route.path;
						if (path === '/order') return;

						const title = `Корзина ${cart_type_name || ''} была удалена. Ваша основная корзина была установлена.`
						notification({title})

						return;
					}

					const path = this.$route.path;
					if (path === '/order') return;

					const title = `Корзина ${cart_type_name || ''} была удалена.`
					notification({title})
				}
			});
		},
		// Обработка событий, связанных с модификацией коллективной корзины (добавление и удаление товаров)
		handleCartModificationSocketEvents()
		{
			this.$socket.io.on("collective-cart-products", ({action, data}) =>
			{
				const cartTypeId = this.$cookie.get('basketId') || this.currentBasket?.id;

				const {cart_type_id, cartInfo, lanch} = data;

				if (!data || cart_type_id !== cartTypeId) return;

				if (lanch)
				{
					this.processLunchUpdate(action, data)
				} else
				{
					this.processMealUpdate(action, data)
				}

				// Обработка изменений цены, грамовки и баллов корзины
				if (cartInfo) this.setCartDetails(cartInfo);
			});
		},
		// Обработка изменения количества блюд в корзине
		processMealUpdate(action, product)
		{
			if (action === "add") this.addMealToCart(product);

			if (action === "remove") this.removeMealFromCart(product.id);
		},
		// Обработка изменения количества ланчей в корзине
		processLunchUpdate(action, lunch)
		{
			if (action === "add") this.addLunchToCart(lunch);

			if (action === "remove") this.removeLunchFromCart(lunch.id);
		},
		// Обработка событий, связанных с участниками коллективной корзины (добавление и удаление людей)
		handleCartUserModificationSocketEvents()
		{
			this.$socket.io.on("collective-cart-users", async ({action, data}) =>
			{
				const {cart_type_id, cart_type_name, name, id, cartInfo} = data;

				if (!cart_type_id) return;

				const isCartTypeIdActive = this.currentBasket?.id === cart_type_id;

				// Обработка сценария, где пользователя удалили из корзины
				if (action === "delete" && id === this.user.ID)
				{
					/**  Если нас удалили из текущей корзины, то удаляем ее и делаем главной Основную,
					 * в противном случае просто удаляем коризну из списка наших корзин */
					if (isCartTypeIdActive)
					{
						await this.deleteAndSetMainCart();

						const title = `Вы больше не являетесь участником корзины ${cart_type_name || ''}. Ваша основная корзина была установлена.`
						notification({title})

						return;
					}

					const title = `Вы больше не являетесь участником корзины ${cart_type_name || ''}.`
					notification({title})
				}

				// Обработка сценариев добавления и удаления участников
				if (isCartTypeIdActive)
				{
					if (action === "add")
					{
						this.addInviteUser(data);

						const title = `Пользователь ${name || ''} присоединился к корзине ${cart_type_name || ''}.`
						notification({title})
					}

					if (action === "delete")
					{
						this.deleteUser({userId: id});

						// Обработка изменений цены, грамовки и баллов корзины
						if (cartInfo) this.setCartDetails(cartInfo);

						const title = `Пользователь ${name || ''} больше не является участником корзины ${cart_type_name || ''}.`
						notification({title})
					}
				}
			});
		},
		// Удаляем корзину и делаем главной Основную
		async deleteAndSetMainCart()
		{
			this.resetCartToDefault();

			await this.getCart();
		},
	}
}