import Vue from 'vue';

/**
 * Функция, которая запускает уведомление.
 *
 * @param {string} title - Заголовок уведомления.
 * @param {string} type - Тип уведомления, который принимает значение 'success', 'warn' или 'error' (по умолчанию 'warn').
 */
export const notification = ({title, type = 'warn'}) =>
{
	Vue.prototype.$notify({
		title: title,
		type: type,
	});
}