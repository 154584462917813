'use strict'
import axios from 'axios';
import cookies from 'vue-cookie';
import qs from "qs";
import {notification} from "@/utils";

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

// todo: перенести сюда работу с корзиной
const cart = {
	state:
		{
			mainBasket:
				{
					collective: false,
					id: '',
					owner: true,
				},
			currentBasket: {
				collective: false,
				id: '',
				owner: true,
			},
			personsCount: 1,
			cart: ['null'],
			basketUsers: [],
			token: null,

			currentIdItem: null,
		},
	getters:
		{
			getIsMyOrder(state)
			{
				if (!state.currentBasket) return false;

				return state.currentBasket?.id && !state.currentBasket?.owner;
			},
			getCurrentBasket(state)
			{
				return state.currentBasket;
			},
			getPersonsCount(state)
			{
				let savedCount = cookies.get('personsCount');
				return savedCount && state.personsCount === 1 ? savedCount : state.personsCount;
			},
			getCart(state)
			{
				return state.cart
			},
			getBasketUsers(state)
			{
				return state.basketUsers;
			},
		},
	mutations:
		{
			setCurrentBasket(state, basket)
			{
				state.currentBasket = basket;
			},
			setBasketUsers(state, data)
			{
				state.basketUsers = data;
			},
			setPersonsCount(state, count)
			{
				cookies.set('personsCount', count, 1);
				state.personsCount = count;
			},
			addInviteUser(state, user)
			{
				state.basketUsers.push(user);
			},
			deleteUser(state, person)
			{
				if (!state.basketUsers.length) return;

				state.basketUsers = state.basketUsers.filter(user => user.id !== person.userId)
			},
			setUserDone(state, userId)
			{
				if (!userId || !state.basketUsers.length) return;

				state.basketUsers = state.basketUsers.map(user => user.id === userId ? {
					...user,
					done: true
				} : user);
			},
			setTokenAfterInvite(state, token)
			{
				state.token = token;
			},
			setCurrentIdItem(state, id)
			{
				state.currentIdItem = id;
			}
		},
	actions:
		{
			async resetCartToDefault({commit, state})
			{
				await cookies.set('basketId', "main");
				commit('setCurrentBasket', state.mainBasket);
			},
			async addToCart({commit, state, rootState}, {id, count, cartId})
			{
				try
				{
					const params = qs.stringify({
						'restId': rootState.address.restarauntId,
						'day': rootState.currentDate,
						'prodId': id,
						'count': count,
						'cartTypeId': state.currentBasket?.id || '',
						cartId
					})

					const {data} = await axios.post('/cart/addMeal/', params);

					if (!data.success) return;

					await this.dispatch('getCart');

					return data;
				} catch (error)
				{
					notification({title: 'Произошла ошибка при добавлении товара в корзину', type: 'error'})
				}
			},
			// приглашенный нажал 'Завершить сборку'
			async userFinished({commit}, ids)
			{
				const params = new URLSearchParams();
				params.append('cartId', ids.cartId);
				params.append('cartTypeId', ids.cartTypeId);
				return await axios.post('/cart/done', params);
			},
			async initiatorFinished({commit}, {status, cartTypeId})
			{
				const params = new URLSearchParams();
				params.append('cartTypeId', cartTypeId);
				params.append('status', status);
				return await axios.post('/cart/cartTypeDone', params);
			},
			async loadBasketUsers({commit}, id)
			{
				try
				{
					const params = new URLSearchParams();
					params.append('id', id);

					const {data} = await axios.get('/cart/users', {params});

					if (!data.success) return;

					// Перемещаем пользователя с isOwner = true на первую позицию
					const ownerUser = data.users.find(user => user.isOwner);
					const ownerIndex = data.users.indexOf(ownerUser);
					if (ownerIndex > 0)
					{
						data.users.unshift(data.users.splice(ownerIndex, 1)[0]);
					}

					commit('setBasketUsers', data.users);

					return data.users;
				} catch (e)
				{
					throw new Error(e);
				}
			},
			async deleteBasketType({commit}, basketTypeId)
			{
				try
				{
					const params = new URLSearchParams();
					params.append('id', basketTypeId)

					const {data} = await axios.post('/cart/deleteType/', params);

					if (!data.success) return;

					cookies.delete("basketId");
				} catch (e)
				{
					throw new Error(e)
				}
			},
			async getBasketTypes({commit, dispatch})
			{
				try
				{
					const {data} = await axios.get('/cart/types/');

					if (!data.success) return;

					if (!data.types.length) return dispatch('resetCartToDefault');

					cookies.set('basketId', data.types[0]?.id);
					commit('setCurrentBasket', data.types[0]);
					commit('setTimeRange', data.types[0].timeRange);
					dispatch('setDate', data.types[0].day);
				} catch (e)
				{
					throw new Error(e)
				}
			},
			async createBasketType({state, commit}, formData)
			{
				const params = new URLSearchParams();

				for (const key in formData) params.append(key, formData[key]);

				return await axios.post('/cart/createType/', params);
			},
			async getTokenAfterInvite({commit}, token)
			{
				try
				{
					const params = new URLSearchParams();
					params.append('token', token)
					const response = await axios.post('/cart/approveInvite', params)
					commit('setTokenAfterInvite', token)
					return response.data
				} catch (e)
				{
					throw new Error(e);
				}
			},
			async deleteUser({commit}, formData)
			{
				try
				{
					const params = new URLSearchParams();
					params.append('cartTypeId', formData.cartTypeId)
					params.append('userId', formData.userId)

					const {data} = await axios.post('/cart/deleteUser', params)

					if (data.success) commit('deleteUser', formData)

					return data;
				} catch (e)
				{
					throw new Error(e)
				}
			},
			//Запрос на изменение количества столовых приборов
			async fetchChangeDeviceQuantity({commit, rootState}, count)
			{
				if (count < 0 || count > rootState.maxPersonsCount) return;

				try
				{
					const params = qs.stringify({
						cartId: rootState.cartId,
						count: count,
					})
					await axios.post('/cart/setPersonsCount/', params);
				} catch (error)
				{
					throw new Error(error)
				}
			},
		}
}
export default cart
