import router from "@/router";
import {TIME_RANGES_EN} from "@/constants";

/**
 * Перенаправляет пользователя в зависимости от указанного временного диапазона.
 *
 * @param {string} timeRange - Выбранный временной диапазон.
 */
export const redirectBasedOnTimeRange = (timeRange) =>
{
	if (!timeRange || !router) return;

	const currentPath = router.currentRoute.path;

	if (currentPath !== `/${TIME_RANGES_EN[timeRange]}`) router.push(`/${TIME_RANGES_EN[timeRange]}`);
}