export const TIME_RANGES = {
	breakfast: 'Завтрак',
	lunch: 'Обед',
	dinner: 'Ужин',
}

export const DAYS = {
	tomorrow: 'Завтра',
	today: 'Сегодня',
}

export const TIME_RANGES_EN = {
	breakfast: 'breakfast',
	dinner: 'dinner',
	lunch: 'menu',
}