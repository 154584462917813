'use strict'
import qs from 'qs'
import axios from 'axios';
import PromocodeManager from '../applets/PromocodeManager';

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

export default {
	namespaced: true,
	state:
		{
			fio: '',
			fioError: '',

			phone: '',
			phoneError: '',

			email: '',
			emailError: '',

			home: '',
			time: '',
			comment: '',
			saveComment: '',
			disabledSavedCard: false,

			currentDeliveryType: '',

			promocodeError: '',
			promocodeData: null,
			promocodeResult: {
				discountSum: 0,
				gifts: []
			},

			points: 0,
			orderInProcess: false,
			cartTypeId: false,
			showPaymentWidget: null,
		},
	getters:
		{
			currentRestaurant(state, getters, stateRoot)
			{
				const rest = stateRoot?.restaraunts?.find(({id}) => id === stateRoot.currentRestarauntId);
				if (rest)
					return rest;
				else
					return {name: 'Ресторан не выбран', ogrn: '', inn: ''}
			},

			currentDeliveryType(state)
			{
				return state.currentDeliveryType;
			},

			currentPromocode(state)
			{
				return state.promocodeData ? state.promocodeData.promoCoupon.COUPON : null;
			},

			currentPromocodeDiscount(state)
			{
				return state.promocodeResult.discountSum;
			},
			currentOrderInProcess(state)
			{
				return state.orderInProcess;
			},
			getShowPaymentWidget(state)
			{
				return state.showPaymentWidget
			},
			getPromocodeError(state)
			{
				return state.promocodeError
			},
			getDisabledSavedCard(state)
			{
				return state.disabledSavedCard
			}
		},
	mutations:
		{
			setDisabledSavedCard(state, value)
			{
				state.disabledSavedCard = value
			},
			saveCartTypeId(state, id)
			{
				state.cartTypeId = id;
			},
			setShowPaymentWidget(state, data)
			{
				state.showPaymentWidget = data;
			},
			setOrderInProcess(state, status)
			{
				state.orderInProcess = status;
			},
			setCurrentDeliveryType(state, type)
			{
				state.currentDeliveryType = type;
			},
			/**
			 * personal = {fio phone email}
			 */
			setPersonal(state, personal)
			{
				state.fio = personal.fio;
				state.phone = personal.phone;
				state.email = personal.email;
			},

			/**
			 * delivery = {home time}
			 */
			setDelivery(state, delivery)
			{
				state.home = delivery.home;
				state.time = delivery.time;
			},
			setSaveComment(state, value)
			{
				state.saveComment = value;
			},
			setComment(state, value)
			{
				state.comment = value;
			},
			/**
			 * params = {key value}
			 */
			setStateValue(state, params)
			{
				// debugger;
				// state.fioError = params.value;
				// Vue.set(state[params.key],params.value)
				state[params.key] = params.value;
			},
			setOrderError(state, error)
			{
				state.phoneError = error;
			},

			SET_PROMOCODE_ERROR(state, error)
			{
				state.promocodeError = error;
			},

			SET_PROMOCODE_DATA(state, data)
			{
				state.promocodeData = data;
			},

			SET_PROMOCODE_RESULT(state, result)
			{
				state.promocodeResult = result;
			},
		},
	actions:
		{
			// Валидирует правильность заполнения полей на форме заказа
			validate({state, commit})
			{
				let noErrors = true;
				let validationSettings = [
					{fieldCode: 'fio', errorVar: 'fioError', type: 'string'},
					{fieldCode: 'phone', errorVar: 'phoneError', type: 'phone'},
					{fieldCode: 'email', errorVar: 'emailError', type: 'email'},
				];
				let excludeRequiredFields =
					[
						// 	'fio', 'email'
					];

				for (let field of validationSettings)
				{
					let fieldValue = state[field.fieldCode];

					if ((typeof fieldValue == 'undefined' || fieldValue == '') && field.fieldCode !== 'email')
					{
						commit('setStateValue', {key: field.errorVar, value: 'Обязательное поле'});
						if (excludeRequiredFields.indexOf(field.fieldCode) == -1)
							noErrors = false;
						continue;
					}

					if (field.type == 'phone')
					{
						let mask = /[^\d]+/g;
						let maskWithoutEight = /7?([1-7,9])\d{9}/g;
						let phone = fieldValue.replace(mask, '');

						if (phone.length !== 11 || !maskWithoutEight.test(phone))
						{
							commit('setStateValue', {key: field.errorVar, value: 'Телефон введен некорректно'});
							noErrors = false;
							continue;
						}
					}

					if (field.type == 'email' && state['email'])
					{
						let mask = /^\w.+@\w.+\.\w{2,10}$/gi;
						if (!mask.test(fieldValue))
						{
							commit('setStateValue', {key: field.errorVar, value: 'Email введен некорректно!'});
							noErrors = false;
							continue;
						}
					}

					commit('setStateValue', {key: field.errorVar, value: ''});
				}

				return noErrors;
			},
			// Отправляет заказ на сервер
			async make({state, commit, getters, rootGetters, rootState})
			{
				let rest = this.state.restaraunts.find(rest =>
				{
					return rest.id === this.state.currentRestarauntId
				});

				const usedSavedCard = rootGetters["personal/getCurrentCard"]?.ID || ''

				let data = qs.stringify({
					'cartId': rootState.cartId,
					'email': state.email,
					'phone': state.phone.replaceAll('-', '').replaceAll(' ', '').replaceAll('(', '').replaceAll(')', ''),
					'fio': state.fio,
					'address': rootGetters.getAddress,
					'time': state.time,
					'dayStr': rootGetters.currentDayStr,
					'restaurant_id': this.state.currentRestarauntId,
					'restaurant': rest ? rest.name : '',
					'points': state.points,
					'comment': state.comment,
					'saveComment': state.saveComment,
					'promocode': state.promocode === 'normal' ? getters.currentPromocode : this.state.auth.promocode,

					'personsCount': rootGetters.getPersonsCount,
					'coords': rootState.address.coords.lat + ';' + rootState.address.coords.lan,
					'fromMobileApp': typeof BXMobileApp != "undefined",
					'deliveryType': state.currentDeliveryType,
					'cartTypeId': state.cartTypeId,

					'usedSavedCard': state.disabledSavedCard ? '' : usedSavedCard
				});

				let result = await axios.post('/cart/makeOrder/', data);
				commit('setShowPaymentWidget', result.data.show_payment_widget)
				if (result.data.success)
				{

					if (rootState.cartSumm - state.promocodeResult.discountSum > 0)
					{

						if (typeof BXMobileApp != "undefined")
						{
							let resUrlParts = result.data.payUrl.split("/personal/");
							let resUrl = resUrlParts[0] + "/mobapp/m_personal/" + resUrlParts[1];
							window.location.href = resUrlParts[0] + "/mobapp/menu"; //переход на меню/главную
							BXMobileApp.PageManager.loadPageModal({ //открытие нового окна с оплатой
								url: resUrl,
								title: "Оплата",
							});
						} else
						{
							let userAgent = navigator.userAgent.toLowerCase();

							if (userAgent.indexOf('firefox') != -1)
								window.location.href = result.data.payUrl;
						}

					} else
					{
						if (typeof BXMobileApp != "undefined")
							window.location.href = `${window.location.origin}/mobapp`
						else
							window.location.href = `${window.location.origin}/application`
					}
				}
				return result;
			},

			/** Загрузить и применить промокод к текущему заказу */
			async checkPromocode({state, commit, dispatch}, promocode)
			{
				try
				{
					const {data} = await axios.post(`/cart/checkCoupon/`, qs.stringify({promocode}));

					if (!data.success)
					{
						commit('SET_PROMOCODE_ERROR', data.msg);

						commit('SET_PROMOCODE_DATA', null);

						return data;
					}

					commit('SET_PROMOCODE_ERROR', '');

					commit('SET_PROMOCODE_DATA', {promoCoupon: data.promoCoupon, saleDiscount: data.saleDiscount});

					await dispatch('applyPromocode');

					return data;
				} catch (e)
				{
					throw new Error(e);
				}
			},

			/** Пересчитать стоимость с учетом промокода */
			async applyPromocode({state, commit, rootState, rootGetters, rootCommit})
			{

				if (!state.promocodeData)
				{
					commit('SET_PROMOCODE_RESULT',
						{
							discountSum: 0,
							gifts: []
						});
					return;
				}

				// Обработка купона через менеджер купонов для
				// получения бонусов с учетом текущей корзины
				const result = PromocodeManager.handleCoupon(
					// Корзина
					{
						meals: rootState.cartMeals,
						lunches: rootState.cartLanches
					},

					// Данные заказа
					{fullSumm: rootState.cartSumm, delivery: rootState.cartDeliverySum},

					// Данные промокупона
					state.promocodeData.promoCoupon,
					state.promocodeData.saleDiscount
				);

				commit('SET_PROMOCODE_RESULT', result);
			},
			//Получить детали конкретного заказа
			async fetchOrderDetails({}, orderId)
			{
				try
				{
					const {data} = await axios.post(
						'/personal/detailOrder/',
						qs.stringify({orderId})
					);

					if (data.success) return data;
				} catch (error)
				{
					throw new Error(error);
				}
			}
		},
}
