import axios from 'axios'

export default {
	namespaced: true,
	state:
		{
			fullZones: false,
			currentAddress: '',
			currentAddressPrecision: '',
			currentAddressCity: false,
			map: null,
			needMapUpdate: false,
			isLoading: true,
			dates:
				{
					start: new Date(),
					end: new Date(),
				},
			coords: null,
			ordersData: {},
			userData: {},
			disableSelectButton: false,
		},
	getters: {

		getDeliveryAddress(state)
		{
			return state.currentAddress;
		},

		currentAddressCity(state)
		{
			return state.currentAddressCity;
		},

		mapAfterInit(state)
		{
			return state.map;
		},

		needMapUpdate(state)
		{
			return state.needMapUpdate;
		}
	},
	mutations: {
		setCoords(state, coords)
		{
			state.coords = coords;
		},

		setDates(state, dates)
		{
			state.dates = dates;
		},
		setZones(state, payload)
		{
			state.fullZones = payload;
		},

		setInitedMap(state, payload)
		{
			state.map = payload;
		},

		setDeliveryAddress(state, payload)
		{
			state.currentAddress = payload;
		},

		setCurrentAddressPrecision(state, payload)
		{
			state.currentAddressPrecision = payload;
		},

		setCurrentAddressCity(state, payload)
		{
			state.currentAddressCity = payload;
		},

		setOrdersData(state, data)
		{
			const ordersData = {...data};
			ordersData.rows = ordersData.rows.map(el => ({
				...el,
				ordersList: []
			}));
			state.ordersData = ordersData;
		},

		setUsersData(state, {id, data})
		{
			const idx = state.ordersData.rows.findIndex(el => el.user.id === id)
			if (idx !== -1)
				state.ordersData.rows[idx].ordersList = data.map(userItem =>
				{
					let userOrederItem = {}
					userOrederItem.id = userItem.id
					userOrederItem.date = userItem.date
					userOrederItem.time = userItem.time
					userOrederItem.summ = userItem.summ

					return userOrederItem;
				})
		},

		SET_MAP_UPDATE(state, payload)
		{
			state.needMapUpdate = payload;
		},

		SET_MAP_LOADING(state, payload)
		{
			state.isLoading = payload;
		},

		setDisableSelectButton(state, value)
		{
			state.disableSelectButton = value;
		},
	},
	actions: {

		deliveryAddressAction({state, commit}, payload)
		{
			commit('setDeliveryAddress', payload);
		},

		setNewMapAfterChange({commit}, payload)
		{
			commit('setInitedMap', payload);
		},

		setCurrentAddressPrecision({commit}, payload)
		{
			commit('setCurrentAddressPrecision', payload);
		},

		setCurrentAddressCity({commit}, payload)
		{
			commit('setCurrentAddressCity', payload);
		},

		/**
		 * Получает адрес метки, если проходит валидацию отображается, если нет то невидимая
		 */
		async getAddress({state, dispatch, commit}, data)
		{
			const coords = data.coords
			const {geoObjects} = await ymaps.geocode(coords, {kind: 'house'});
			const firstGeoObject = geoObjects.get(0);

			if (firstGeoObject && firstGeoObject.properties.get('metaDataProperty.GeocoderMetaData.precision'))
			{
				// Об оценке точности ответа геокодера можно прочитать тут: https://tech.yandex.ru/maps/doc/geocoder/desc/reference/precision-docpage/
				const cAddress = firstGeoObject.getAddressLine();
				commit('setDeliveryAddress', cAddress);
				// dispatch('getDisplayAddress', cAddress);
			}
		},

		// получать данные заказа после клика на балун
		async getDataOrders({commit}, payload)
		{
			let ax = axios.create({baseURL: ''});
			const response = await ax.post('/bitrix/services/main/ajax.php?c=intervolga:dict2gis.form&action=getOrdersDetailed&mode=class', payload);
			commit('setOrdersData', response.data.data);
		},
		async getDataUser({commit}, {id, payload})
		{
			let ax = axios.create({baseURL: ''});
			const response = await ax.post('/bitrix/services/main/ajax.php?c=intervolga:dict2gis.form&action=getOrdersForUser&mode=class', payload);
			commit('setUsersData', {id: id, data: response.data?.data?.orders || []});
		},
		/**
		 * Преобразует длинный адрес в обычный
		 * удаляет страну и город
		 */
		getDisplayAddress({commit}, address)
		{
			let countItemsToCut;
			address.indexOf(' область') !== -1 ? countItemsToCut = 2 : countItemsToCut = 0;

			let addressArray = address.split(',');
			addressArray.splice(0, countItemsToCut);

			commit('setDeliveryAddress', addressArray.join(','))
			return addressArray.join(',');
		},

		/**
		 * Получает зоны и сохраняет их в кэш
		 */
		async getZones({dispatch, commit})
		{
			try
			{
				dispatch('getZonesFromCache');
			} catch
			{
				console.error('Не удалось получить зоны из локального кэша');
				localStorage.removeItem('zoneCache');
			}

			let result = await axios.post('/city/getZones/');
			commit('setZones', result.data.zones);
			localStorage['zoneCache'] = JSON.stringify(result.data.zones);
		},

		/**
		 * Загружает зоны из кэша, если он доступен
		 */
		getZonesFromCache({commit})
		{
			if (localStorage['zoneCache'])
			{
				commit('setZones', JSON.parse(localStorage['zoneCache']));
			}
		},

		/**
		 * Отрисовывает зоны
		 */
		drawZones({state, commit}, self)
		{
			if (!self.map) return

			self.map.geoObjects.removeAll();

			for (let index in self.zones)
			{
				var myPolygon = new ymaps.Polygon(
					[self.zones[index].poligon],
					{
						hintContent: self.zones[index].UF_NAME,
						zoneCityId: self.zones[index].UF_CITY
					},
					{
						fill: true,
						fillColor: self.zones[index].status ? 'rgb(13, 152, 31)' : 'rgb(255, 204, 2)',
						strokeColor: '#666666',
						outline: 1,
						opacity: 0.5,
						// interactivityModel: 'default#transparent',
					}
				);

				self.map.geoObjects.add(myPolygon);

				for (let restIndex in self.zones[index].restaurants)
				{
					let data = {
						coords: [self.zones[index].restaurants[restIndex].lon, self.zones[index].restaurants[restIndex].lat],
						name: self.zones[index].restaurants[restIndex].name,
						content: '<div>' + self.zones[index].restaurants[restIndex].name + '</div>'
					}
				}
				myPolygon.options.setParent(self.map.options);
				myPolygon.geometry.setMap(self.map)
				self.polygons.push(myPolygon);
			}

			commit('SET_MAP_LOADING', false);
		},
	}
}
