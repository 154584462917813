'use strict'
import qs from 'qs'
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

const banner = {
	state:
		{
			showTomorrowOrderButton: false
		},
	actions:
		{
			async getBannerData({state, rootState, rootGetters})
			{
				//не удалил может пригодится
				// let day = new Date().getDay();
				// let dayOfWeek = (day === 0 || day === 6 || day === 5);
				// если нет обедов и завтраков
				// let scenario = ((!rootState.currentCityRestIsWork && rootGetters.currentDayStr == 'today' && dayOfWeek) ? 14 : 13);
				//toDo если нет обедов но есть завтраки коды 15, 16
				//toDo если мы на странице зватраков то 13 - если на сегодня нет блюд и время прошло, 14 - если на завтра нет блюд и время прошло
				let response = await axios.get('/banners/getClosedRestaurantsData', {params: {scriptType: 13}});

				if (!response.data.success || !response.data.closedRestaurantData)
					return;

				rootState.closedRestaurantData = response.data.closedRestaurantData;

				if (!rootState.currentCityRestIsWork && rootGetters.currentDayStr != 'today')
					return;

				if (!rootState.currentRestarauntId) return;

				let data = qs.stringify({
					'restId': rootState.currentRestarauntId,
					'day': 'tomorrow'
				});

				response = await axios.post('/restaurant/getMenu/', data);
				if (!response.data.success)
					return;

				state.showTomorrowOrderButton = Boolean(response.data.lanches.length || response.data.meal.length);
			},
		}
}
export default banner;
