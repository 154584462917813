'use strict'
import qs from 'qs'
import axios from 'axios';
import cookies from 'vue-cookie';

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

const address =
	{
		state:
			{
				// адрес ресторана который был вабран последний раз
				// достается из кук
				address: '',
				coords: {},
				restarauntId: false,
				invalidAddress: '',
				mapPopupNotificationType: false,
			},
		getters:
			{
				getMapPopupNotificationType(state)
				{
					return state.mapPopupNotificationType;
				},
				getAddress(state)
				{
					return state.address
				},
				getInvalidAddress(state)
				{
					return state.invalidAddress;
				},
				getCoords(state)
				{
					return state.coords;
				}
			},
		mutations:
			{
				setMapPopupNotificationType(state, value)
				{
					state.mapPopupNotificationType = value;
				},
				/**
				 * Установить адрес доставки который не найден
				 */
				setInvalidAddress(state, address)
				{
					state.invalidAddress = address;
				},
				/**
				 * Установка окончательного адреса
				 */
				setAddress(state, address)
				{
					state.address = address;
					cookies.set('address', address, 12);
				},

				/**
				 * Записывает введенные координаты
				 */
				setCoords(state, coords)
				{
					state.coords = {
						lat: coords.lat,
						lan: coords.lan
					};
					cookies.set('coords', JSON.stringify(coords), 12);
				},

				/**
				 * Записывает id выьранного ресторана
				 */
				setRestarauntId(state, id)
				{
					cookies.set('restid', id, 12);
					state.restarauntId = id;
				},
			},
		actions:
			{
				mapPopupNotificationTypeAction({commit}, payload)
				{
					commit('setMapPopupNotificationType', payload);
				},
				changeInvalidAddress({commit}, payload)
				{
					commit('setInvalidAddress', payload);
				}
			},
	}
export default address;
