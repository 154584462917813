import qs from 'qs'
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

export default {
	namespaced: true,
	state: {
		menu: {},
	},
	mutations: {
		SET_MENU_CACHE(state, {rest, day, timeRange, cache})
		{
			if (!(rest in state.menu)) state.menu[rest] = {};

			if (!(day in state.menu[rest])) state.menu[rest][day] = {};

			state.menu[rest][day][timeRange] = cache;
		},
	},
	actions: {
		async updateMenuCache({commit}, {rest, day, typeId, timeRange})
		{
			if (!rest) return;

			try
			{
				const params = qs.stringify({
					'restId': rest,
					'day': day,
					'typeId': typeId,
					'timeRange': timeRange,
				});

				const {data} = await axios.post('/restaurant/getMenu/', params);

				if (!data.success) return;

				this.dispatch("getCurrentCity");

				commit('SET_MENU_CACHE', {rest, day, timeRange, cache: data});

				return data;
			} catch (error)
			{
				throw new Error(error)
			}
		},
	}
}
