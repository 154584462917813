<template>
	<div
		class="popup-overlay"
		v-if="visible"
		tabindex="1"
	>
		<div
			@click.stop
			class="popup-block"
			:class="{
				'popup-block_no-scroll': hideCloseBtn,
			}"
			v-click-outside="close"
		>
			<div
				class="popup-close"
				@click="close"
				:class="{'popup-close_hidden': hideCloseBtn}"
			>
				<img
					src="~@/assets/img/close.svg"
					alt="Close"
				>
			</div>
			<slot/>
		</div>
	</div>
</template>

<script>
export default {
	name: "Popup",
	props:
		{
			visible:
				{
					type: Boolean,
					default: false,
				},
			hideCloseBtn:
				{
					type: Boolean,
					default: false,
				},
			onlyClosePopup:
				{
					type: Boolean,
					default: false,
				}
		},
	methods:
		{
			close()
			{
				this.$store.dispatch("openPopup", null);
				if (!this.onlyClosePopup) this.$emit("update:visible", false);
				this.$emit('closePopup');
				this.$el.remove();
			},
			toggleAppPullDownTrigger(value)
			{
				return !!process.env.VUE_APP_MOBILE && appHelper.appPullDownTrigger(value);
			},
		},
	watch:
		{
			visible:
				{
					handler: function (newValue)
					{
						let body = document.querySelector("body");

						if (newValue)
						{
							this.toggleAppPullDownTrigger(false);
							document.body.appendChild(this.$el);
							body.classList.add("page-no-scroll");
						} else
						{
							body.classList.remove("page-no-scroll");
							this.toggleAppPullDownTrigger(true);
						}
					},
					deep: true,
				},
		},
	destroyed()
	{
		if (this.$el && this.$el.parentNode) this.$el.parentNode.removeChild(this.$el);

		document.querySelector("body").classList.remove("page-no-scroll");
	},
};
</script>

<style lang="scss">
.popup-overlay
{
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	inset: 0;
	z-index: 1100;
	display: flex;
	align-items: center;
	justify-content: center;
}

.popup-block
{
	width: fit-content;
	position: relative;

	background-color: white;
	max-height: 100svh;
	box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.15);
	border-radius: 24px;
	overflow: hidden;
	overflow-y: auto;

	&::-webkit-scrollbar
	{width: 0;}
}

.popup-close
{
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	width: 24px;
	height: 24px;
	z-index: 2;

	&_hidden
	{display: none;}
}

@media (max-width: 1200px)
{
	.popup-overlay
	{align-items: flex-start;}

	.popup-close
	{
		top: 15px;
		right: 15px;
		z-index: 2;

		img
		{
			width: 24px;
			height: 24px;
		}
	}
}

@media (max-width: 990px)
{
	.popup-block
	{
		max-width: 680px;
		width: 100%;
	}
}

@media (max-width: 576px)
{
	.popup-block
	{
		margin: 0;
		max-width: none;
		border-radius: 0;
		height: 100%;
	}
}
</style>
