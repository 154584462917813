import axios from 'axios';

const chat = {
	namespaced: true,
	state:
		{
			isChatActive: false,
			chatToken: null,
			messages: [],
			file: '',
			pages: '',
			isChatVisible: null,
			messageCount: 0,
			filePath: '',
		},
	getters:
		{
			getChatStatus(state)
			{
				return state.isChatActive;
			},
			getMessages(state)
			{
				return state.messages;
			},
			getFiles(state)
			{
				return state.file;
			},
			getPageCount(state)
			{
				return state.pages;
			},
			getFilePaths(state)
			{
				return state.filePath;
			}
		},
	mutations:
		{
			resetMessageCount(state)
			{
				state.messageCount = 0;
			},
			setMessages(state, messages)
			{
				state.messages = [...messages, ...state.messages];
			},
			addMessage(state, message)
			{
				state.filePath = '';
				state.messages = state.messages.concat(message);
				if (message.fromSupport && !state.isChatActive)
					state.messageCount++;
			},
			setChatVisibility(state, status)
			{
				state.isChatVisible = status;
			},
			setChatStatus(state, status)
			{
				state.isChatActive = status;
			},
			setSendedFiles(state, file)
			{
				state.file = file;
			},
			setFilePath(state, path)
			{
				state.filePath = path;
			},
			setPages(state, pages)
			{
				state.pages = pages;
			}
		},
	actions:
		{
			async chatHide({commit})
			{
				let response = await axios.get('/chat/showSupportChat/');
				commit('setChatVisibility', response.data.success);
			},
			async loadFilesToServ({commit}, file)
			{
				try
				{
					const formData = new FormData();
					formData.append('file', file);
					const response = await axios.post('/chat/uploadFile/', formData);
					commit('setSendedFiles', response.data.files);
					commit('setFilePath', response.data.info[0].path);
				} catch (e)
				{
					throw e;
				}
			},
			async loadMessagesList({commit}, pageData = null)
			{
				try
				{
					const response = await axios.get('/chat/messages/', {
						params: {
							page: pageData
						}
					})
					if (response.data)
					{
						commit('setPages', response.data.pages);
					}
					return response.data.messages;
				} catch (e)
				{
					throw e;
				}
			}
		}
}

export default chat;
